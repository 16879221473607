export const delay = (time) => new Promise ((resolve) => {setTimeout(() => {resolve(true)},time)});
export const extractEzivrResponse = (hoursOfOperation) => {
    if(hoursOfOperation.holiday === 'yes') {
      return false;
    }
    if(hoursOfOperation.timeofday === 'yes') {
      return false;
    }
    return true;
  };
